import fetchData from './fetchData'
import {parseJSON, checkHttpStatus} from '../shared/utils'
import config from '../config/index'

async function getData(sellerId, payload) {
  return fetchData(`${config.api.baseUrl}?class=seller&action=info&seller_id=${sellerId}`, 'POST', payload)
    .then(parseJSON)
    .then(checkHttpStatus);
}

export {
  getData
}