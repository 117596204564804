import React, {useState, useEffect, useCallback}  from 'react';

import {getData} from "../api/mainRequest";

import { Layout, Avatar, Row, Col, Card, Button, Divider, BackTop, Spin, Icon, Result } from 'antd';

import {
    GeolocationControl,
    Map,
    Placemark,
    YMaps,
    ZoomControl
} from "react-yandex-maps";

import getColors from 'get-image-colors'

const { Footer, Content } = Layout;
const { Meta } = Card;

const mapData = {
    center: [56.044326986433624, 92.85211039648425],
    zoom: 10,
};

const Landing = ((props) => {

    const [mainData, setMainData] = useState({});
    const [points, setPoints] = useState([]);
    const [loadingYandexMap, setLoadingYandexMap] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [mainColors, setMainColors] = useState([]);

    useEffect(() => {

        const {match} = props;

        getData(match.params.sellerId).then(res => {
            setMainData(res.result[0][0])
            setPoints(res.result[1])
            getColors(res.result[0][0].s_logo, (err, colors) => {
                if (err) throw err;
                setMainColors(colors.map(color => color.hex()))
            })
        }).catch( res => setHasError(true))

    }, [props]);

    const goToMap = useCallback((coords) => {
        if (coords) {
            let coordArr = coords.split(',');
            window.location.assign(`https://yandex.ru/maps/?ll=${coordArr[1]},${coordArr[0]}&z=15&mode=search&text=${mainData.s_name}`);
        }
    },[mainData])

    return (
        !hasError ?
            <>
                <Layout>
                    <Content >
                        <Row type="flex" justify="center" align="middle" gutter={16} style={{margin:0, background: mainColors[0]}} className="head">
                            <Col><Avatar size={150} src={mainData.s_logo} style={{boxShadow: '0 0 3px 2px #fff'}} /></Col>
                            <Col className="head-text" style={{color: mainColors.length > 1 ? '#fff' : '#000'}}>{mainData.s_name}</Col>
                        </Row>

                        <Divider style={{fontSize: '1.5rem', paddingTop: '10vh', color: mainColors[0]}}>Магазины</Divider>

                        <Row type="flex" justify="center" style={{margin:0}} gutter={16}>
                            {points.map((item, key) =>
                                <Col key={key} md={6} xs={16} style={{marginBottom: '10vh'}}>
                                    <Card
                                        onClick={e => goToMap(item.s_position)}
                                        hoverable
                                        cover={<img alt={mainData.s_name} src={mainData.s_logo} />}
                                    >
                                        <Meta title={item.s_name} description={
                                            <>
                                                <Row type="flex" justify="start">
                                                    {item.s_address}
                                                </Row>

                                                <Row type="flex" justify="end">
                                                    <Col>
                                                        <Button style={{marginTop: 10, background: mainColors[0], border:0}} type="primary" icon="mobile" href={`tel:+7${item.s_phone}`}>{item.s_phone}</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        } />
                                    </Card>
                                </Col>
                            )}
                        </Row>

                        <Divider style={{fontSize: '1.5rem', paddingTop: '10vh', color: mainColors[0]}}>Как нас найти?</Divider>

                        <Spin
                            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
                            spinning={loadingYandexMap === null}>
                            <Row type="flex" justify="center">
                                <Col style={{width: '100%', minHeight: 100}}>
                                    <YMaps query={{ apikey: 'ca774bb1-3885-4fa2-8709-30c4f4242fa0' }}>
                                        <Map
                                            width={'100%'}
                                            height={300}
                                            onLoad={e => setLoadingYandexMap(e)}
                                            defaultState={mapData}
                                            modules={["templateLayoutFactory", "layout.ImageWithContent", "geocode"]}>
                                            {points.map((coordinate, key) =>
                                                <Placemark
                                                    key={key}
                                                    geometry={coordinate.s_position ? coordinate.s_position.split(',') : []}
                                                    properties={{
                                                        hintContent: mainData.s_name
                                                    }}
                                                    options={{
                                                        preset: 'islands#blueHomeIcon',
                                                        iconColor: '#096dd9'
                                                    }}
                                                    modules={
                                                        ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                                    }
                                                />)}
                                            <ZoomControl options={{ float: 'right' }} />
                                            <GeolocationControl options={{ float: 'left' }} />
                                        </Map>
                                    </YMaps>
                                </Col>
                            </Row>
                        </Spin>
                    </Content>
                    <Footer className="block-black">
                        Maxbonus {new Date().getFullYear()}
                    </Footer>
                </Layout>
                <BackTop />
            </>
        :
            <Result
                status="404"
                title="404"
                subTitle="Извините, но данной торговой сети не найдено!"
            />
    );
})

export default Landing;