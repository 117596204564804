import React from 'react';

/**
 *
 * @param errors
 * @constructor
 */
export function ValidationError(errors) {
  Error.call(this, errors);
  this.name = "ValidationError";

  this.errors = errors;
  this.message = 'Error validation';
  this.status = 422;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, ValidationError);
  } else {
    this.stack = (new Error()).stack;
  }
}

/**
 *
 * @type {Error}
 */
ValidationError.prototype = Object.create(Error.prototype);

/**
 *
 * @param constants
 * @returns {*}
 */
export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    acc[constant] = constant;
    return acc;
  }, {});
}

/**
 *
 * @param initialState
 * @param reducerMap
 * @returns {function(*=, *)}
 */
export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer
      ? reducer(state, action.payload)
      : state;
  };
}

/**
 *
 * @param response
 * @returns {*}
 */
export function checkHttpStatus(response) {
  // if success status
  if ( response.status.error === 0 ) {
    return response
  } else {
    if (response.status === 422) {
      throw new ValidationError(response.data)
    }

    //TODO: so bad code?
    if (response.status.message === 'нет такой сессии') {
      localStorage.removeItem('sessionId');
      console.log(response.status.message)
      window.location.replace(`${process.env.PUBLIC_URL}/auth`);
    }

    throw new Error(response.status.message)
  }
}

/**
 *
 * @param response
 * @returns {*}
 */
export function parseJSON(response) {
  return response.json()
}

/**
 *
 * @param key
 * @returns {string}
 */
function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

/**
 *
 * @param key
 */
function underscoreToCamel(key) {
  return key.replace(/_([a-z])/g, function (m, w) {
    return w.toUpperCase();
  });
}

/**
 *
 * @param errors
 * @param toCamelCase
 * @returns {{}}
 */
export function getFormErrors(errors = [], toCamelCase = true) {
  const formErrors = {};
  errors.map(err => {
    let name = toCamelCase ? underscoreToCamel(err.field) : err.field;
    formErrors[name] = err.message;
  });

  return formErrors;
}

