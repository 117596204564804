import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import 'antd/dist/antd.css';
import './App.css';
import Landing from './components'

function App() {
  return (
      <BrowserRouter>
          <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/:sellerId?`} component={Landing}/>
          </Switch>
      </BrowserRouter>
  );
}

export default App;
